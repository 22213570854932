var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Treinamentos")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"to":"/main/EscolaFeriados/create","color":"primary"}},[_vm._v("Criar")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading-text":"Carregando...","fixed-header":"","height":"70vh","footer-props":{
          itemsPerPageOptions: [-1]
        },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('OpenNewTabComponent',{attrs:{"propObjectLabel":("" + (item.title)),"propObjectId":item.id,"propToolName":"Treinamentos"}})]}},{key:"item.ativo",fn:function(ref){
        var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.data",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.allowDelete)?_c('v-icon',{on:{"click":function($event){return _vm.deleteFeriado(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { treinamentoStore, mainStore, userToolStore, } from '@/utils/store-accessor';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.search = '';
        this.headers = [
            {
                text: 'Título',
                value: 'title',
            },
            {
                text: 'Descrição',
                value: 'description',
            },
            {
                text: 'Duração',
                value: 'duration',
            },
            {
                text: 'Departamento',
                value: 'departamento.nome',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = false;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Treinamentos) {
            return userToolStore.userToolsIndexed.Treinamentos[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Treinamentos) {
            return userToolStore.userToolsIndexed.Treinamentos[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Treinamentos) {
            return userToolStore.userToolsIndexed.Treinamentos[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Treinamentos) {
            return userToolStore.userToolsIndexed.Treinamentos[0].allow_delete;
        }
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get items() {
        return treinamentoStore.treinamentos;
    }
    async deleteFeriado(item) {
        this.$swal({
            title: 'Confirma a exclusão do feriado?',
            text: 'Após exclusão a sala não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await feriadoStore.deleteFeriado(item.id);
                // @ts-ignore
                await feriadoStore.getFeriados();
                this.loading = false;
            }
        });
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-treinamentos-edit', params: { id } });
    }
    async mounted() {
        await treinamentoStore.getTreinamentos();
    }
};
List = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
        },
    })
], List);
export default List;
